/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from "aws-amplify";

export const awsConfig: any = {
  aws_appsync_graphqlEndpoint: "https://weghgae5pjacrly5ajk23w3egu.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-df3aiervsbbo3mhavi7ijsej24",
  Auth: {
    userPoolId: "eu-west-1_AwzNC3Qnp",
    userPoolWebClientId: "6aibqruu14unmuj0hfil1cvjq8",
    oauth: {
      domain: "verax-backend-prod.auth.eu-west-1.amazoncognito.com",
      scope: ["email", "openid", "phone", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://veraxdbs.productsupport.dsm.com",
      redirectSignOut: "https://veraxdbs.productsupport.dsm.com",
      responseType: "code"
    }
  },
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken()
      };
    }
  }
};
