import { useEffect } from "react";
import { Auth } from "aws-amplify";

function SSOLogout() {
  useEffect(() => {
    clearData();

    if (window.gigya) return;

    const script = document.createElement("script");
    script.src = "https://cdns.gigya.com/js/gigya.js?apiKey=" + process.env.REACT_APP_ADOBE_GIGYA_KEY;
    script.onload = onLoad;
    document.body.appendChild(script);
  });

  const onLoad = () => {
    window.gigya?.accounts.logout({callback: (e:any) => {

      Auth.signOut({
        global: false
      });
    }})
  };

  const clearData = () => {
    sessionStorage.clear();
  };

  return <div></div>;
}

export default SSOLogout;
